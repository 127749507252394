@import "@/styles/_variables.scss";
    @import "@/styles/_mixins.scss";
    @import "@/styles/_variables.scss";
  
.container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.iframeGame {
  width: 100%;
}
